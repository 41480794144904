<template>
  <FormPromotion
    :loading="loading"
    :item="promotionDataToEdit"
  />
</template>

<script>
import { onUnmounted, ref } from '@vue/composition-api'

import router from '@/router'
import store from '@/store'

import promotionAgStoreModule from '@promotionAg/promotionAgStoreModule'

export default {
  components: {
    FormPromotion: () => import('../components/FormPromotion.vue'),
  },

  setup() {
    const loading = ref(true)
    const PROMOTION_AG_APP_STORE_MODULE_NAME = 'app-promotionAg'

    // Register module
    if (!store.hasModule(PROMOTION_AG_APP_STORE_MODULE_NAME)) {
      store.registerModule(PROMOTION_AG_APP_STORE_MODULE_NAME, promotionAgStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROMOTION_AG_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(PROMOTION_AG_APP_STORE_MODULE_NAME)
      }
    })

    const promotionDataToEdit = ref({})

    store.dispatch('app-promotionAg/getPromotionById', { id: router.currentRoute.params.id })
      .then(({ data }) => {
        const normalizeData = {
          ...data,
          discount: data.discountPercent || data.discountAmount,
          discountType: data.discountPercent > 0 ? '%' : 'VND',
          flightTrips: data.flightTrips || [],
          isSOTO: data.flightTrips.includes('!VN-!XX'),
          isSITI: data.flightTrips.includes('VN-!VN'),
        }
        promotionDataToEdit.value = normalizeData
        loading.value = false
      })
      .catch(error => {
        if (error) {
          promotionDataToEdit.value = undefined
        }
      })
      .finally(() => {
        loading.value = false
      })

    return {
      loading,
      promotionDataToEdit,
    }
  },
}
</script>
